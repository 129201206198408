import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "@/store"
import useJwt from '@/auth/jwt/useJwt'

import { get } from "lodash"
import {
  MUTATE_LOGIN_STATUS,
  MUTATE_SETTINGS,
  MUTATE_USER_DATA,
  MUTATE_PHONE_VERIFICATION_STATUS,
} from "@/store/config/mutation-keys"

// Routes
import { getHomeRouteForLoggedInUser, isUserLoggedIn } from '@/auth/utils'
import { canNavigate, checkLoginTokenStatus } from '@/libs/acl/routeProtection'

import authRoutes from "./routes/auth";
import adminRoutes from "./routes/admin";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [

    ...authRoutes,
    ...adminRoutes,
    {
      path: '*',
      name: 'error-404',
      component: () => import('@/pages/auth/error-404'),
      meta: {
        layout: 'full',
        resource: 'AuthRoute',
        action: 'read',
        roles: ["admin", "vendor", "client", "anonymous"]
      },
    },
  ],
})

async function beforePasswordReset(to, _from, next) {
  const { token: passwordResetToken } = to.query;
  if (!passwordResetToken) {
    store.commit(`auth/${MUTATE_LOGIN_STATUS}`, false)
    return next({ name: "auth-login" });
  }

  try {
    useJwt.authService.setPasswordResetToken(passwordResetToken);
    await useJwt.authService.confirmTokenValidity("password_reset_token");
    return next();
  } catch (error) {
    return next({ name: "auth-forgot-password", query: { error: "Password reset token has expired" } });
  }
}

async function beforeEnteringAuthenticatedRoute(to, from, next) {
  const userIsLoggedIn = store.getters[`auth/isLoggedIn`]
  if (to.name !== from.name) {
    let authorized = userIsLoggedIn;
    let user = store.getters[`auth/userData`];
    let settings = store.getters[`auth/settings`];

    if (!(userIsLoggedIn && user && settings)) {
      console.log("[check token]")
      const authCheckResponse = await checkLoginTokenStatus();
      user = authCheckResponse.user;
      settings = authCheckResponse.settings;
      authorized = authCheckResponse.authorized;

      store.commit(`auth/${MUTATE_LOGIN_STATUS}`, true);
      store.commit(`auth/${MUTATE_USER_DATA}`, user);
      store.commit(`auth/${MUTATE_SETTINGS}`, settings);
    }

    if (!authorized) {
      console.log("[not authorized]")
      return next({ name: 'auth-login' });
    }

    const phoneVerificationStatus = get(user, 'meta.phone_verified', false);
    const requirePhoneVerification = get(settings, 'security_settings.require_admin_phone_verification', false);
    store.commit(`auth/${MUTATE_PHONE_VERIFICATION_STATUS}`, phoneVerificationStatus);

    if (!phoneVerificationStatus && requirePhoneVerification) {
      return next({ name: 'auth-verify-phone' });
    }

    const requireMFA = get(settings, 'security_settings.require_admin_mfa', false);
    if (!user.mfa_conf && requireMFA && to.name !== "security-mfa") {
      console.log("[to security mfa]")
      sessionStorage.setItem("last_page_accessed", to.path)
      return next({ name: "security-mfa" })
    }

    const daysBeforePasswordExpire = user.days_before_password_expire;

    if (daysBeforePasswordExpire <= 0 && to.name !== 'security-password-change') {
      console.log("[to change password]")
      return next({ name: 'security-password-change' })
    }
    /**
     * redirect user to dashboard.
     */
    if (to.name === "app-root") {
      console.log("[to home]")
      return next({ name: "admin-home" })
    }

    // check is user has access to route.
    if (canNavigate(to, user.user_type)) {
      console.log("[to proceed]")
      return next();
    }

    return next({ name: 'misc-not-authorized' });
  }

  return next();
}

router.beforeEach(async (to, from, next) => {
  try {
    const { token: hasAccountOrPasswordConfirmationToken } = to.query;

    const toPathRequiresAuth = get(to, 'meta.requireAuth', false);
    const redirectIfLoggedIn = get(to, 'meta.redirectIfLoggedIn', false);

    const isLoggedIn = isUserLoggedIn();

    const routesRequiringPasswordSettings = [
      "auth-reset-password",
      "auth-forgot-password-mfa"
    ];

    if (routesRequiringPasswordSettings.includes(to.name)) {
      const response = await useJwt.authService.getRegistrationSettings();
      const { password_complexity_settings } = response.data.data;
      store.commit(`auth/${MUTATE_SETTINGS}`, { password_complexity_settings });
    }

    /**
     * password reset route handle
     */
    if (hasAccountOrPasswordConfirmationToken && to.name === "auth-reset-password") {
      await beforePasswordReset(to, from, next)
    }

    if (to.name === "auth-verify-phone" && isLoggedIn) {
      const { authorized, user, settings } = await checkLoginTokenStatus()

      if (!authorized) {
        return next({ name: 'auth-login' });
      }

      const phoneNumberVerified = get(user, 'meta.phone_verified', false);
      const requirePhoneVerification = get(settings, 'security_settings.require_admin_phone_verification', false);

      // redirects if phone number already verified
      if (phoneNumberVerified && requirePhoneVerification && to.name === "auth-verify-phone") {
        return next("/");
      }
    }


    /**
     * path requires authentication but user is logged in.
     */
    if (toPathRequiresAuth && isLoggedIn) {
      await beforeEnteringAuthenticatedRoute(to, from, next)
    }

    /**
     * path requires authentication but user is not logged in.
     */
    if (toPathRequiresAuth && !isLoggedIn) {
      return next({ name: 'auth-login' });
    }

    if (!toPathRequiresAuth && isLoggedIn && redirectIfLoggedIn) {
      console.log("---> requires no authentication with redirect and is logged in");
      const { authorized, user, settings } = await checkLoginTokenStatus()

      const in_maintenance_mode = get(settings, 'enable_maintenance_mode', false)
      if (user.user_type !== "admin" && in_maintenance_mode) {
        if (to.name === 'misc-under-maintenance') {
          return next();
        }
        return next({ name: 'misc-under-maintenance' });
      }

      if (!in_maintenance_mode && to.name === 'misc-under-maintenance') {
        return next("/");
      }

      console.log("---> authorized", authorized);
      if (authorized) {
        store.commit(`auth/${MUTATE_LOGIN_STATUS}`, true);
        store.commit(`auth/${MUTATE_USER_DATA}`, user);
        store.commit(`auth/${MUTATE_SETTINGS}`, settings);

        const { name } = getHomeRouteForLoggedInUser(user.user_type);

        const currentUserHomeRoute = router.resolve({ name });
        if (!currentUserHomeRoute) {
          return next();
        }

        if (canNavigate(currentUserHomeRoute.route, user.user_type)) {
          return next({ name, query: get(to, "query") });
        }

        return next({ name: 'misc-not-authorized' })
      }

      // proceed to intended destination because user is not logged in.
      // here user is only redirect if they are logged in.
      store.commit(`auth/${MUTATE_LOGIN_STATUS}`, false);
      return next();
    }

    return next()
  } catch (error) {
    useJwt.authService.clearAccessToken();

    if (!to.name !== "auth-login") {
      store.commit(`auth/${MUTATE_LOGIN_STATUS}`, false)
      next({ name: "auth-login" });
    }
    return next();
  }
})


router.beforeResolve((to, _from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    // eslint-disable-next-line no-undef
    NProgress.start()
  }
  next()
})

router.afterEach(async (to) => {
  // Complete the animation of the route progress bar.
  // eslint-disable-next-line no-undef
  NProgress.done()

  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  try {
    const requireAuth = get(to, 'meta.requireAuth');
    const hasLogPageName = get(to, 'meta.logPageName');
    const isLoggedIn = isUserLoggedIn();

    if (requireAuth && hasLogPageName && isLoggedIn) {
      await useJwt.authService.logPageAccess({
        route: to.path,
        comment: hasLogPageName
      });
    }
  } catch (error) {
    //
  }
});


export default router
