export default {
  // Auth Endpoints
  logEndpoint: '/auth/login',
  logsEndpoint: '/auth/logs',
  loginEndpoint: '/auth/login',
  logoutEndpoint: '/auth/logout',
  registerEndpoint: '/auth/register',
  updateUserProfileEndpoint: '/auth/profile',
  completeOnboardEndpoint: '/auth/onboard',
  confirmPhoneEndpoint: '/auth/confirm-phone',
  confirmEmailEndpoint: '/auth/confirm-email',
  confirmTokenValidityEndpoint: '/auth/check',
  resetPasswordEndpoint: '/auth/reset-password',
  userNotificationsEndpoint: '/auth/notifications',
  changePasswordEndpoint: '/auth/password/change',
  userSupportTicketsEndpoint: '/auth/support-tickets',
  registrationSettingsEndpoint: '/auth/registration/settings',
  requestPasswordResetEndpoint: '/auth/request-password-reset',
  requestPhoneVerificationEndpoint: '/auth/request-phone-verification',
  checkPasswordResetValidityEndpoint: '/auth/check-password-reset-token',
}
