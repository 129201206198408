export default class AdminJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  search(search_type, params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminSearchEndpoint}/${search_type}/autocomplete`, {
      params
    })
  }

  /** Admin Dashboard */
  fetchDashboardStatsData(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminDashboardEndpoint}/stats`, {
      params
    })
  }

  fetchDashboardGraphData(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminDashboardEndpoint}/graph`, {
      params
    })
  }

  fetchDashboardSignUpsGraphData(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminDashboardEndpoint}/signups-graph`, {
      params
    })
  }

  /** Admin Settings */
  updateGeneralSettings(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminSettingsEndpoint}/${id}`, payload)
  }


  /** Admin Users */
  createAdmin(payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminsEndpoint}`, payload)
  }

  fetchAdmins(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminsEndpoint}`, {
      params
    })
  }

  fetchAdmin(user_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminsEndpoint}/${user_id}`)
  }

  updateAdmin(user_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminsEndpoint}/${user_id}`, payload)
  }

  resendAdminAccountConfirmationEmail(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminsEndpoint}/${user_id}/account/confirmation/resend`)
  }
  /** Admin Users */

  updateUser(user_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminUsersEndpoint}/${user_id}/update`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  deactivateUser(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminUsersEndpoint}/${user_id}/deactivate`, {})
  }

  approveUser(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminUsersEndpoint}/${user_id}/approve`, {})
  }

  deleteUserAvatar(user_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminUsersEndpoint}/${user_id}/avatar`, {})
  }

  resendAccountConfirmationEmail(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminUsersEndpoint}/${user_id}/account/confirmation/resend`)
  }

  resetAdminUserMFA(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminsEndpoint}/${user_id}/account/mfa/reset`)
  }

  resetUserMFA(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminUsersEndpoint}/${user_id}/account/mfa/reset`)
  }

  fetchUsers(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminUsersEndpoint, {
      params
    })
  }

  fetchUser(user_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminUsersEndpoint}/${user_id}`, {})
  }

  fetchProviders(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminProvidersEndpoint, {
      params
    })
  }

  fetchChurchMap(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminChurchMapEndpoint, {
      params
    })
  }

  updateChurchMap(id, data) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminChurchMapEndpoint}/${id}`, data)
  }

  deleteChurchMap(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminChurchMapEndpoint}/${id}`)
  }

  createChurchMap(data) {
    return this.axiosIns.post(this.jwtConfig.admin.adminChurchMapEndpoint, data)
  }

  actionProvider(id, status) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminProvidersEndpoint}/${id}/action`, {
      status
    })
  }

  fetchAdminPolicies(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/policy`, { params })
  }

  deleteAdminPolicy(policy_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminPermissionsEndpoint}/policy/${policy_id}`)
  }

  exportUsers(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminUsersEndpoint}/export/all`, {
      params,
      responseType: "blob"
    })
  }

  importUsers(formData) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminUsersEndpoint}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  /** Admin Permissions */
  fetchPermissions(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminPermissionsEndpoint, {
      params
    })
  }

  fetchPermission(permission_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/${permission_id}`, {})
  }

  createPermission(payload = {}) {
    return this.axiosIns.post(this.jwtConfig.admin.adminPermissionsEndpoint, payload)
  }

  updatePermission(payload = {}) {
    return this.axiosIns.put(this.jwtConfig.admin.adminPermissionsEndpoint, payload)
  }

  /** Admin Blog Posts */
  createNewBlogPost(formData) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminBlogsEndpoint}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  updateBlogPost(blog_id, formData) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminBlogsEndpoint}/${blog_id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  deleteBlogPost(blog_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminBlogsEndpoint}/${blog_id}`, {})
  }

  /** Admin FAQ */
  fetchFaq(faq_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminFAQEndpoint}/${faq_id}`, {})
  }

  fetchFaqs(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminFAQEndpoint}`, {
      params
    })
  }

  updateFaq(faq_id, formData) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminFAQEndpoint}/${faq_id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }


  deleteFaq(faq_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminFAQEndpoint}/${faq_id}`, {})
  }

  createNewFAQ(formData) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminFAQEndpoint}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  /** Admin Logs */
  fetchLogs(log_type, params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminLogsEndpoint}/${log_type}`, {
      params
    })
  }

  fetchAuditTrails(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminAuditTrail, {
      params
    })
  }

  exportAuditTrails(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminAuditTrail}/export`, {
      params,
      responseType: "blob"
    })
  }

  fetchUpdatedAuditTrails(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminLogsEndpoint, {
      params
    })
  }

  exportUpdatedAuditTrails(params) {
    return this.axiosIns.get(this.jwtConfig.admin, {
      params,
      responseType: "blob"
    })
  }

  exportLogs(log_type, params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminLogsEndpoint}/${log_type}/export`, {
      params,
      responseType: "blob"
    });
  }

  createPolicy(payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminPermissionsEndpoint}/policy`, payload)
  }

  updatePolicy(policy_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminPermissionsEndpoint}/policy/${policy_id}`, payload)
  }

  getPolicy(policy_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/policy/${policy_id}`)
  }

  getResources(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resources`, {
      params
    })
  }

  createResource(payload) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resources`, payload);
  }

  getResource(resource_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resources/${resource_id}`)
  }

  updateResource(resource_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resources/${resource_id}`, payload)
  }

  deactivateResource(resource_id, payload) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resources/${resource_id}`, payload)
  }

  createResourceAction(payload) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resource-action`, payload);
  }

  getResourceAction(resource_action_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resource-action/${resource_action_id}`)
  }

  updateResourceAction(resource_action_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resource-action/${resource_action_id}`, payload)
  }

  deleteResourceAction(resource_action_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resource-action/${resource_action_id}`)
  }

  getResourceActions(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resource-action`, {
      params
    })
  }

  fetchPermissionsSchema(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/schema`, {
      params
    })
  }

  getUserRole(user_role_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/user-role/${user_role_id}`)
  }

  getUserRoles(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/user-role`, {
      params
    })
  }

  createUserRole(payload) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminPermissionsEndpoint}/user-role`, payload);
  }

  updateUserRole(user_role_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminPermissionsEndpoint}/user-role/${user_role_id}`, payload)
  }

  deleteUserRole(user_role_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminPermissionsEndpoint}/user-role/${user_role_id}`)
  }

  // Event Center
  createEventCenter(payload = {}) {
    return this.axiosIns.post(this.jwtConfig.admin.adminEventCenterEndpoint, payload)
  }

  updateEventCenter(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminEventCenterEndpoint}/${id}`, payload)
  }

  deleteEventCenter(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminEventCenterEndpoint}/${id}`)
  }

  fetchEventCenters(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminEventCenterEndpoint, {
      params
    })
  }

  exportEventCenters(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminEventCenterEndpoint}/query/export`, {
      params,
      responseType: "blob"
    })
  }

  // Event Center Block 
  createEventCenterBlock(payload = {}) {
    return this.axiosIns.post(this.jwtConfig.admin.adminEventBlockEndpoint, payload)
  }

  updateEventCenterBlock(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminEventBlockEndpoint}/${id}`, payload)
  }

  deleteEventCenterBlock(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminEventBlockEndpoint}/${id}`)
  }

  fetchEventCenterBlocks(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminEventBlockEndpoint, {
      params
    })
  }

  exportEventBlocks(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminEventBlockEndpoint}/query/export`, {
      params,
      responseType: "blob"
    })
  }

  // Event Center Accommodation 
  createEventCenterAccommodation(payload = {}) {
    return this.axiosIns.post(this.jwtConfig.admin.adminEventAccommodationEndpoint, payload)
  }

  updateEventCenterAccommodation(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminEventAccommodationEndpoint}/${id}`, payload)
  }

  deleteEventCenterAccommodation(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminEventAccommodationEndpoint}/${id}`)
  }

  fetchEventCenterAccommodations(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminEventAccommodationEndpoint, {
      params
    })
  }

  exportEventCenterAccommodations(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminEventAccommodationEndpoint}/query/export`, {
      params,
      responseType: "blob"
    })
  }

  /** Admin Events */
  createNewEvent(formData) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminEventEndpoint}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  fetchEvent(id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminEventEndpoint}/${id}`, {})
  }

  updateEvent(event_id, formData) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  deleteEvent(event_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}`, {})
  }

  publishEvent(event_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/publish`, {})
  }

  unPublishEvent(event_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/unpublish`, {})
  }

  fetchEvents(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminEventEndpoint}`, {
      params
    })
  }

  addFieldDraft(event_id, payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/fields`, payload)
  }

  updateField(event_id, field_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/fields/${field_id}`, payload)
  }

  deleteField(event_id, field_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/fields/${field_id}`, {})
  }

  addActivity(event_id, payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/activities`, payload)
  }

  updateActivity(event_id, field_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/activities/${field_id}`, payload)
  }

  deleteActivity(event_id, field_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminEventEndpoint}/${event_id}/activities/${field_id}`, {})
  }
}
