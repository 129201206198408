export default {
  // mfa endpoints
  enrollMfaEndpoint: '/mfa/enroll',
  resendMfaOTPEndpoint: '/mfa/otp/resend', // same as the enroll MFA
  verifyAndActivateMfaEndpoint: '/mfa/activate',
  requestDeactivateMfaEndpoint: '/mfa/deactivate-request',
  deactivateMfaEndpoint: '/mfa/deactivate',
  validateAndAuthenticateEndpoint: '/mfa/authenticate',
  verifyMFAPasswordResetRequestEndpoint: '/mfa/verify-password-reset-request'
}
