export default [
  {
    path: '/home',
    name: 'admin-home',
    component: () => import('@/pages/home'),
    meta: {
      action: "read",
      pageTitle: 'Dashboard',
      resource: "AdminRoute",
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
      roles: ["admin"],
      requireAuth: true,
      logPageName: "Admin Dashboard",
    },
  },
  /** Admin Users */
  {
    path: '/users',
    name: 'admin-users',
    component: () => import('@/pages/users/list'),
    meta: {
      action: "read",
      pageTitle: 'Users',
      resource: "AdminListUsersRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Users',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Admin List Users",
      showPagination: true,
      roles: ["admin"],
      permissions: ["users:CanList"]
    },
  },
  // {
  //   path: '/user/:id',
  //   name: 'admin-user-single',
  //   component: () => import('@/pages/users/view'),
  //   meta: {
  //     action: "read",
  //     pageTitle: 'User Details',
  //     resource: "users",
  //     breadcrumb: [
  //       {
  //         text: 'Home',
  //         to: { name: 'admin-home' },
  //       },
  //       {
  //         text: 'List Users',
  //         to: { name: 'admin-users' },
  //       },
  //       {
  //         text: 'User Details',
  //         active: true,
  //       }
  //     ],
  //     roles: ["admin"],
  //     requireAuth: true,
  //     permissions: ["users:CanView"],
  //     logPageName: "Admin User Details",
  //   },
  // },
  {
    name: 'admin-update-user',
    path: '/user/:id/update',
    component: () => import('@/pages/users/update'),
    resource: "users",
    meta: {
      action: "read",
      pageTitle: 'Update User',
      resource: "AdminUpdateUserRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Users',
          to: { name: 'admin-users' },
        },
        {
          text: 'Update',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      logPageName: "Admin Update User",
      permissions: ["users:CanUpdate"]
    },
  },
  {
    path: '/job-providers',
    name: 'admin-providers',
    component: () => import('@/pages/users/list/providers'),
    meta: {
      action: "read",
      pageTitle: 'Users',
      resource: "AdminListUsersRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Job Providers',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Admin List Job Providers",
      showPagination: true,
      roles: ["admin"],
      permissions: ["users:CanList"]
    },
  },
  {
    path: '/church-map',
    name: 'admin-church-map',
    component: () => import('@/pages/church-map/list.vue'),
    meta: {
      action: "read",
      pageTitle: 'Users',
      resource: "AdminListUsersRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Church Map',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Admin List Church Map",
      showPagination: true,
      roles: ["admin"],
      permissions: ["church_map:CanList"]
    },
  },
  /** ADMIN USERS */
  {
    path: '/admin-users',
    name: 'admin',
    component: () => import('@/pages/users/list/admins'),
    meta: {
      action: "read",
      pageTitle: 'Users',
      resource: "AdminListUsersRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Users',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["admin:CanList"],
      logPageName: "Admin List Users",
    },
  },
  {
    name: 'admin-new-user',
    path: '/admin-users/new',
    component: () => import('@/pages/users/new'),
    meta: {
      action: "read",
      pageTitle: 'New Staff',
      resource: "AdminAddUsersRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Users',
          to: { name: 'admin' },
        },
        {
          text: 'New Staff',
          active: true,
        },
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["admin:CanAddNew"],
      logPageName: "Admin Create User",
    },
  },
  {
    name: 'admin-single',
    path: '/admin-users/:id',
    component: () => import('@/pages/users/update/admin'),
    meta: {
      action: "read",
      pageTitle: 'User Details',
      resource: "admin",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Admins',
          to: { name: 'admin' },
        },
        {
          text: 'User Details',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["admin:CanView"],
      logPageName: "Admin User Details",
    },
  },
  /** END OF ADMIN USERS */

  // Admin Logs
  {
    path: '/admin-logs',
    name: 'admin-logs',
    component: () => import('@/pages/logs/admin'),
    meta: {
      action: "read",
      pageTitle: 'Admin Logs',
      resource: "AdminListAdminLogsRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'Admin Logs',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["admin_logs:CanList"],
      logPageName: "Admin List Logs",
    },
  },
  {
    path: '/user-logs',
    name: 'user-logs',
    component: () => import('@/pages/logs/user'),
    meta: {
      action: "read",
      pageTitle: 'User Logs',
      resource: "AdminListLogsRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'User Logs',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["logs:CanList"],
      logPageName: "User List Logs",
    },
  },
  {
    path: '/audit-trail',
    name: 'audit-trail',
    component: () => import('@/pages/logs/audit-trail'),
    meta: {
      action: "read",
      pageTitle: 'Audit Trail',
      resource: "AdminListLogsRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'Audit Trail',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["audit_trail:CanList"],
      logPageName: "List Audit Trail",
    },
  },


  /** Admin Settings */
  {
    path: '/settings',
    name: 'admin-settings',
    component: () => import('@/pages/settings'),
    meta: {
      action: "read",
      pageTitle: 'Settings',
      resource: "AdminSettingsRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'Settings',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["settings:CanView"],
      logPageName: "Admin View Settings",
    },
  },
  {
    path: '/policies',
    name: 'admin-policies',
    component: () => import('@/pages/administrations/policies/list-policies'),
    meta: {
      action: "read",
      pageTitle: 'Policies',
      resource: "AdminListPermissionsRoute",
      breadcrumb: [
        {
          text: 'List Policies',
          to: { name: 'admin-policies' },
        },
        {
          text: 'List',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["policy:CanList"],
      logPageName: "Admin List Policies",
    },
  },
  {
    path: '/policies/:id',
    name: 'admin-view-policies',
    component: () => import('@/pages/administrations/policies/view-policy'),
    meta: {
      action: "read",
      pageTitle: 'Policy',
      resource: "policy",
      breadcrumb: [
        {
          text: 'List Policies',
          to: { name: 'admin-policies' },
        },
        {
          text: 'View',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["policy:CanView"],
      logPageName: "Admin View Policies",
    },
  },
  {
    path: '/create-policy',
    name: 'admin-new-policy',
    component: () => import('@/pages/administrations/policies/new-policy'),
    meta: {
      action: "read",
      pageTitle: 'Add Policy',
      resource: "AdminListPermissionsRoute",
      breadcrumb: [
        {
          text: 'List Policies',
          to: { name: 'admin-policies' },
        },
        {
          text: 'New',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["policy:CanAddNew"],
      logPageName: "Admin Create Policy",
    },
  },
  /** Admin Permission Resource */
  {
    path: '/resources',
    name: 'admin-resources',
    component: () => import('@/pages/administrations/resources/list-resources'),
    meta: {
      action: "read",
      pageTitle: 'Resources',
      resource: "AdminListPermissionsRoute",
      breadcrumb: [
        {
          text: 'List Resources',
          to: { name: 'admin-resources' },
        },
        {
          text: 'List',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["resource:CanList"],
      logPageName: "Admin List Resources",
    },
  },
  {
    path: '/create-resource',
    name: 'admin-new-resource',
    component: () => import('@/pages/administrations/resources/new-resource'),
    meta: {
      action: "read",
      pageTitle: 'Resource',
      resource: "AdminListPermissionsRoute",
      breadcrumb: [
        {
          text: 'Resources',
          to: { name: 'admin-resources' },
        },
        {
          text: 'Add Resource',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      logPageName: "Admin New Resources",
      permissions: ["resource:CanAddNew"],
    },
  },
  {
    path: '/update-resource/:id',
    name: 'admin-update-resource',
    component: () => import('@/pages/administrations/resources/update-resource'),
    meta: {
      action: "read",
      pageTitle: 'Resource',
      resource: "resource",
      breadcrumb: [
        {
          text: 'Resources',
          to: { name: 'admin-resources' },
        },
        {
          text: 'Update Resource',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["resource:CanView"],
      logPageName: "Admin Update Resources",
    },
  },

  /** Admin Permission Resource Actions */
  {
    path: '/resource-actions',
    name: 'admin-resource-actions',
    component: () => import('@/pages/administrations/resource-actions/list-resource-actions'),
    meta: {
      action: "read",
      pageTitle: 'Resource Actions',
      resource: "AdminListPermissionsRoute",
      breadcrumb: [
        {
          text: 'List Resource Actions',
          to: { name: 'admin-resource-actions' },
        },
        {
          text: 'List',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["resource_action:CanList"],
      logPageName: "Admin List Resource Actions",
    },
  },
  {
    name: 'admin-new-resource-action',
    path: '/create-resource-action/:id',
    component: () => import('@/pages/administrations/resource-actions/new-resource-action'),
    meta: {
      action: "read",
      pageTitle: 'Add Resource Action',
      resource: "AdminListPermissionsRoute",
      breadcrumb: [
        {
          text: 'Resource Actions',
          to: { name: 'admin-resource-actions' },
        },
        {
          text: 'Add Resource Action',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      logPageName: "Admin Add Resource Actions",
      permissions: ["resource_action:CanView"],
    },
  },
  {
    name: 'admin-update-resource-action',
    path: '/update-resource-action/:id/:resource_id',
    component: () => import('@/pages/administrations/resource-actions/update-resource-action'),
    meta: {
      action: "read",
      pageTitle: 'Resource Action',
      resource: "resource_action",
      breadcrumb: [
        {
          text: 'Resources',
          to: { name: 'admin-resources' },
        },
        {
          text: 'Update Resource Action',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["resource_action:CanView"],
      logPageName: "Admin Update Resource Action",
    },
  },

  /** Admin Permission User Roles */
  {
    path: '/user-roles',
    name: 'admin-user-roles',
    component: () => import('@/pages/administrations/user-roles/list'),
    meta: {
      action: "read",
      pageTitle: 'User Role',
      resource: "AdminListPermissionsRoute",
      breadcrumb: [
        {
          text: 'List User Roles',
          to: { name: 'admin-user-roles' },
        },
        {
          text: 'List',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["user_role:CanList"],
      logPageName: "Admin List User Roles",
    },
  },
  {
    path: '/create-user-role',
    name: 'admin-new-user-role',
    component: () => import('@/pages/administrations/user-roles/new-user-role'),
    meta: {
      action: "read",
      pageTitle: 'Add Admin User Role',
      resource: "user_role",
      breadcrumb: [
        {
          text: 'User Roles',
          to: { name: 'admin-user-roles' },
        },
        {
          text: 'Add Admin User Role',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      logPageName: "Add Admin User Role",
      permissions: ["user_role:CanAddNew"],
    },
  },
  {
    path: '/update-user-role/:id',
    name: 'admin-update-user-role',
    component: () => import('@/pages/administrations/user-roles/update-user-role'),
    meta: {
      action: "read",
      pageTitle: 'Add Admin User Role',
      resource: "user_role",
      breadcrumb: [
        {
          text: 'User Roles',
          to: { name: 'admin-user-roles' },
        },
        {
          text: 'Update Admin User Role',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["user_role:CanView"],
      logPageName: "Update Admin User Role",
    },
  },

  /** Admin Blogs */
  {
    path: '/blogs',
    name: 'admin-blogs',
    component: () => import('@/pages/blogs/list'),
    meta: {
      action: "read",
      pageTitle: 'Blogs',
      resource: "AdminListBlogPostsRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'Blogs',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["blogs:CanList"],
      logPageName: "Admin List Blogs",
    },
  },
  {
    path: '/blog/:id',
    name: 'admin-single-blog',
    component: () => import('@/pages/blogs/view'),
    meta: {
      pageTitle: 'Blog Details',
      action: "read",
      resource: "blogs",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List',
          to: {
            name: 'admin-blogs'
          },
        },
        {
          text: 'Details',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["blogs:CanView"],
      logPageName: "Admin View Blog Details",
    },
  },
  {
    path: '/blogs/new',
    name: 'admin-new-blog',
    component: () => import('@/pages/blogs/new'),
    meta: {
      action: "read",
      pageTitle: 'New Blog',
      resource: "AdminNewBlogPostRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List',
          to: {
            name: 'admin-blogs'
          },
        },
        {
          text: 'New',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["blogs:CanAddNew"],
      logPageName: "Admin Create Blog Post",
    },
  },
  {
    path: '/blog/:id/update',
    name: 'admin-update-blog',
    component: () => import('@/pages/blogs/update'),
    meta: {
      pageTitle: 'Update Blog',
      action: "read",
      resource: "blogs",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List',
          to: {
            name: 'admin-blogs'
          },
        },
        {
          text: 'Update',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["blogs:CanView"],
      logPageName: "Admin Update Blog Post",
    },
  },

  /** FAQs */
  {
    path: '/faqs',
    name: 'admin-faqs',
    component: () => import('@/pages/faqs/list'),
    meta: {
      action: "read",
      pageTitle: "FAQs",
      resource: "faqs",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'Faqs',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["faqs:CanList"],
      logPageName: "Admin List Faqs",
    },
  },
  {
    path: '/faq/:id',
    name: 'admin-single-faq',
    component: () => import('@/pages/faqs/view'),
    meta: {
      action: "read",
      pageTitle: 'FAQ Details',
      resource: "faqs",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'Faqs',
          active: true,
        },
        {
          text: 'Detail',
          active: true,
        }

      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["faqs:CanView"],
      logPageName: "Admin View Faq",
    },
  },
  {
    path: '/faqs/new',
    name: 'admin-new-faq',
    component: () => import('@/pages/faqs/new'),
    meta: {
      action: 'read',
      pageTitle: 'New FAQ',
      resource: 'AdminNewFaqRoute',
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List',
          to: {
            name: 'admin-faqs'
          },
        },
        {
          text: 'New',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["faqs:CanAddNew"],
      logPageName: "Admin Create New FAQ",
    },
  },
  {
    path: '/faqs/:id/update',
    name: 'admin-update-faq',
    component: () => import('@/pages/faqs/update'),
    meta: {
      action: "read",
      pageTitle: 'Update FAQ',
      resource: "faqs",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List',
          to: {
            name: 'admin-faqs'
          },
        },
        {
          text: 'Update',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["faqs:CanView"],
      logPageName: "Admin Update Faq",
    },
  },
]
